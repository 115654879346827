import {LogUtils} from '../utils/logUtils';
import {collectionClassHelper} from '../decorators/database/collectionClass';
import {ModelRevision} from '../models/modelRevision';
import {UxComponent} from '../models/ux/uxComponent';
import {UxComponentCollection} from '../models/ux/uxComponentCollection';
import {UxLayout} from '../models/ux/uxLayout';
import {UxConfig} from '../models/ux/uxConfig';
import {User} from '../models/user/user';
import {UxComposite} from '../models/ux/uxComposite';
import {CommerceStock} from '../models/commerce/commerceStock';
import {CommerceProduct} from '../models/commerce/commerceProduct';
import {CommerceOffer} from '../models/commerce/commerceOffer';
import {CommerceOrder} from '../models/commerce/commerceOrder';
import {CommerceTransaction} from '../models/commerce/commerceTransaction';
import {CommerceTransactionCollection} from '../models/commerce/commerceTransactionCollection';
import {CommercePayment} from '../models/commerce/commercePayment';
import {CommerceSchedule} from '../models/commerce/commerceSchedule';
import {CommerceContent} from '../models/commerce/commerceContent';
import {ManagedMail} from '../models/user/managedMail';
import {Note} from '../models/note';
// import {Attachment} from '../models/attachment';
import {Commerce3ds} from '../models/commerce/commerce3ds';
import {Tracking} from '../models/tracking';
import {Permission} from '../models/permission';
import {Raw} from '../models/raw';
import {customModelLoader} from '../custom/customModelLoader';
import {Progressor} from '../models/progressor';
import {OptOut} from '../models/optOut';
import {Content} from "../models/content";
import {EmailRecord} from "../models/emailRecord";
import {TimeSheet} from "../models/timesheet";
import { CdpChargeback } from '../models/cdpchargeback';
import { ModelAddon } from '../models/modelAddon';

export class ModelFactory {
  constructor() {
    // Force Importing
    LogUtils.null(ModelRevision);
    LogUtils.null(UxComposite);
    LogUtils.null(UxComponent);
    LogUtils.null(UxComponentCollection);
    LogUtils.null(UxLayout);
    LogUtils.null(UxConfig);
    LogUtils.null(User);
    LogUtils.null(Permission);
    LogUtils.null(ManagedMail);
    LogUtils.null(Tracking);
    LogUtils.null(CommerceStock);
    LogUtils.null(CommerceProduct);
    LogUtils.null(CommerceOffer);
    LogUtils.null(CommerceOrder);
    LogUtils.null(CommerceTransaction);
    LogUtils.null(CommerceTransactionCollection);
    LogUtils.null(CommercePayment);
    LogUtils.null(CommerceSchedule);
    LogUtils.null(CommerceContent);
    LogUtils.null(Commerce3ds);
    LogUtils.null(Note);
    // LogUtils.null(Attachment);
    LogUtils.null(Raw);
    LogUtils.null(Content);
    LogUtils.null(Progressor);
    LogUtils.null(OptOut);
    LogUtils.null(EmailRecord);
    LogUtils.null(TimeSheet);
    LogUtils.null(CdpChargeback);
    LogUtils.null(ModelAddon);
    customModelLoader.load();
  }

  public factory(collectionName, doc) {
    try {
      const prototype = collectionClassHelper.getClassByKeyValue('name', collectionName).target;
      const modelBase = new prototype.constructor(doc);
      return modelBase;
    } catch (e) {
      LogUtils.error(e, collectionName, doc);
    }
  }
}

export let modelFactory = new ModelFactory();

