import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { User } from 'src/common/models/user/user';
import { CommerceOrder } from 'src/common/models/commerce/commerceOrder';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UxComposite } from 'src/common/models/ux/uxComposite';
import { Note } from 'src/common/models/note';
import { collectionClassHelper } from '../../../common/decorators/database/collectionClass';
import { SpinnerService } from 'src/clientCommon/services/spinner.service';
import { JsonService } from 'src/clientCommon/services';
import { serverPaths } from '../../../common/helpers/pathHelpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogUtils } from '../../../common/utils/logUtils';
import { ServiceHelperService } from '../../../clientCommon/services/serviceHelper.service';
import { emailUtils } from '../../../common/utils/emailUtils';
import { custom } from '../../custom/custom.class';
import * as moment from "moment";
import { CommerceToken } from 'src/common/models/commerce/commerceToken';
import { CommerceContent } from 'src/common/models/commerce/commerceContent';
import { peopleSearchProductKeys } from '../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { commerceUrls } from 'src/common/models/commerce/commerceEvent';

@Component({
    selector: 'app-csr-customer-activity',
    templateUrl: './customer-activity.component.html',
    styleUrls: ['./customer-activity.component.scss'],
    standalone: false
})

export class CustomerActivityComponent implements OnInit {
  @Input() user: User;
  @Input() baseCommerceOrder: CommerceOrder;
  @Input() uxComposite: UxComposite;
  @Input() customerUxComposite: UxComposite;
  @Input() commerceToken: CommerceToken;

  @Input() agentAddress: any = "";
  @Input() agentPhoneNumber: any = "";
  @Input() brandName: string = "";
  @Input() noteData: any;
  @Input() editSummary: any = false;
  @Input() adminFlag: any = false;
  @Input() commerceUserActivityDataSource: Array<any> = [];
  @Input() savedReports: any;
  @Input() savedReportsLoadMore: boolean;
  @Input() searchInfo: any;
  @Input() searchInfoLoadMore: boolean;
  @Input() trackingDisplay: any = {};
  //@Output() notifyUpdateUser: EventEmitter<{user:User, confirmMsg?: string}> = new EventEmitter();

  constructor(private jsonService: JsonService,) {

  }

  activityColumns = ['dateTime', 'report'];
  timeFormat = 'yyyy-MM-dd hh:mm:ss aa';
  custom = custom;

  searchInfoInitialCount = 20;
  searchInfoTotalCount = 0;
  
  savedReportsInitialCount = 20;
  savedReportsTotalCount = 0;

  //@Output() onSuccess = new EventEmitter<void>();

  async ngOnInit() {
    if (this.searchInfoLoadMore === true) {
      this.loadActivity('searchInfo', 0, this.searchInfoInitialCount);
    } else {
      this.searchInfoTotalCount = this.searchInfo?.length ?? 0;
    }
    
    if (this.savedReportsLoadMore === true) {
      this.loadActivity('savedReports', 0, this.savedReportsInitialCount);
    } else {
      this.savedReportsTotalCount = this.savedReports?.length ?? 0;
    }
  }

//   updateUser(user, message?: string) {
//     this.notifyUpdateUser.emit({user, confirmMsg: message});
//   }

  getAddress(commerceToken) {
    const { street1, state, city, zip } = commerceToken.billingAddress;
    return `${street1} ${city} ${state} ${zip}`;
  }
  
  getCardInfo(commerceToken) {
    let ccNumber = '';
    if (commerceToken) {
      const bin = commerceToken.bin;
      const last4 = commerceToken.last4;
      const length = commerceToken?.length - last4?.length - bin?.length;
      if (length) {
        ccNumber = bin;
        ccNumber += new Array(length).fill('*').join('');
        ccNumber += last4;
      }
    }

    return ccNumber;
  }

  hasTitle(commerceContent: CommerceContent) {
    if (
      commerceContent &&
      commerceContent.tempClient &&
      commerceContent.tempClient.raw &&
      commerceContent.tempClient.raw.person &&
      commerceContent.tempClient.raw.person[0]
    ) {
      return true;
    }

    return false;
  }

  getShortTitleByCommerceContent(commerceContent: CommerceContent) {
    if (this.hasTitle(commerceContent)) {
      if (commerceContent.productKey === peopleSearchProductKeys.nameSearch) {
        return commerceContent.tempClient.raw.person[0].fullName;
      } else if (
        commerceContent.productKey === peopleSearchProductKeys.phoneSearch
      ) {
        return commerceContent.content[0].contentInfo.searchContent[0]
          .contentInfo.phone;
      }
    }
  }

  getLocation(commerceContent: CommerceContent) {
    if (this.hasTitle(commerceContent)) {
      return commerceContent.tempClient.raw.person[0].cityStates?.[0];
    }
  }  

  async loadActivity(type: 'searchInfo' | 'savedReports', skip: number, limit: number) {
    try {
      const results = await this.jsonService.json(commerceUrls.csrFindCustomer + '/' + this.user._id + `/${type}`, {
        param: {
          skip,
          limit,
        },
      });
  
      const items = results?.['data']?.items ?? [];
  
      const total = results?.['data']?.total ?? 0;
  
      if (!items?.length) {
        return;
      }
  
      if (skip > 0) {
        this[type] = [...this[type], ...items];
      } else {
        this[type] = items;
      }
  
      this[`${type}LoadMore`] = this[type].length < total;
      this[`${type}TotalCount`] = total;
    } catch (e) {
      LogUtils.error(e);
    }
  }
}  