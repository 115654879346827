import { LogUtils } from "../../common/utils/logUtils";
import { UxComposite } from "../../common/models/ux/uxComposite";
import { idProtectionProductKeys } from "../../common/custom/idProtection/idProtectionKeys";
import {idProtectionUtils} from "../../common/custom/idProtection/idProtectionUtils";
import { CommerceService } from "../../clientCommon/services/commerce.service";

export class SpyIdHelper {
    idProtectionFeatures = {
        idProtectionTheftRecovery: {
            key: "idProtectionTheftRecovery",
            enabled: false
        },
        idProtectionLostWallet: {
            key: "idProtectionLostWallet",
            enabled: false
        },
        idDarkWebMonitoring: {
            key: "idDarkWebMonitoring",
            enabled: false
        },
        idBreachScanReport: {
            key: "idBreachScanReport",
            enabled: false
        },
        idProtectionFraudInsurance: {
            key: "idProtectionFraudInsurance",
            enabled: false
        },
        idProtectionFraudInsurance1M: {
            key: "idProtectionFraudInsurance1M",
            enabled: false
        }
    }

    constructor() {}

    getIdProtectionEnabledFeatures(uxComposite: UxComposite, commerceService: CommerceService, options?: {force?: boolean}): Promise<any> {
        return commerceService.findCommerceOrders(options?.force === true)
        .then((commerceOrders) => {
            Object.keys(idProtectionProductKeys).forEach((productKey) => {
                const valid = idProtectionUtils.hasValidProductKey(commerceOrders, productKey);
                if(valid) {
                    this.idProtectionFeatures[productKey].enabled = commerceService.hasProductInOrder(uxComposite, commerceOrders, [productKey]);
                }
            });
            return Promise.resolve(this.idProtectionFeatures)
        })
        .catch(e => {
            LogUtils.error(e)
        })
    }

    isIdProtectionEnabled(commerceStorage): boolean {
        return (commerceStorage?.idProtectionFeatures?.idDarkWebMonitoring?.enabled ||
            commerceStorage?.idProtectionFeatures?.idProtectionTheftRecovery?.enabled ||
            commerceStorage?.idProtectionFeatures?.idProtectionLostWallet?.enabled ||
            commerceStorage?.idProtectionFeatures?.idBreachScanReport?.enabled ||
            commerceStorage?.idProtectionFeatures?.idProtectionFraudInsurance?.enabled ||
            commerceStorage?.idProtectionFeatures?.idProtectionFraudInsurance1M?.enabled
            )
    }

     getAssignedVictimNum(uxComposite) {
      const defaultNumber = "(888) 998-1825";
      const brandId = uxComposite.brandId.toLowerCase();
      const phoneNums = uxComposite.getUxcomp('comp.brand.customer.phone.spyId')
      return phoneNums? phoneNums: defaultNumber;
    }
 }
