import { Injectable, signal } from "@angular/core";
import { SpyIdHelper } from "../../clientCommon/helper/spyIdHelper";
import { UxComposite } from "../../common/models/ux/uxComposite";
import { LogUtils } from "../../common/utils/logUtils";
import { ResponseEvent } from "../../common/event/responseEvent";
import { CommerceContent } from "../../common/models/commerce/commerceContent";
import { CommerceService } from "./commerce.service";
import { AlertService, AuthenticationService } from ".";

@Injectable()
export class IdProtectionService {
  constructor(
    private commerceService: CommerceService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) {}

  private idProtectionFeatures: any = {};

  private spyIdHelper = new SpyIdHelper();

  private initialized = false;

  private onLoadedResolver;

  readonly idProtectionEnabled = signal(false);

  readonly onLoaded = new Promise((resolve) => {
    this.onLoadedResolver = resolve;
  });

  // check if id protection offer is applicable, if yes then find it's commerceContent and update the commerceStorage accordingly
  async initIdProtection(options: {
    uxComposite: UxComposite,
    force?: boolean,
  }) {
    try {
      if (options?.force !== true && this.initialized) {
        return;
      }

      if (!this.initialized) {
        const previousStorage = this.commerceService.getStorage();
        if (previousStorage?.npsPopupDisabled === true) {
          this.commerceService.storage = { npsPopupDisabled: true };
        } else {
          this.commerceService.storage = {};
        }
        this.commerceService.saveStorage();
      }

      this.initialized = true;

      const idProtectionFeatures = await this.spyIdHelper.getIdProtectionEnabledFeatures(
        options.uxComposite, 
        this.commerceService,
        {
          force: options?.force,
        },
      );

      if (idProtectionFeatures?.idDarkWebMonitoring?.enabled ||
        idProtectionFeatures?.idProtectionTheftRecovery?.enabled ||
        idProtectionFeatures?.idProtectionLostWallet?.enabled ||
        idProtectionFeatures?.idBreachScanReport?.enabled ||
        idProtectionFeatures?.idProtectionFraudInsurance?.enabled ||
        idProtectionFeatures?.idProtectionFraudInsurance1M?.enabled
      ) {          
        await this.processCommerceStorage(idProtectionFeatures, options);
      }
    } catch (e) {
      LogUtils.error(e);
    } finally {
      try {
        const commerceStorage = this.commerceService.getStorage()
  
        const idProtectionCommerceContentId = commerceStorage?.idProtectionContentId;
        const idProtectionFrontEnabled = options.uxComposite.get("comp.member.idProtection.enable") === 'true'? true: false;  
        const idProtectionBackendEnabled = options.uxComposite.get("comp.member.idProtection.registration.enable") === 'true'? true: false;    
        if (idProtectionBackendEnabled && idProtectionFrontEnabled && idProtectionCommerceContentId) {
          this.idProtectionEnabled.set(true);
        }
        this.commerceService.updateStorage({
          ...commerceStorage,
          idProtectionFeatures: this.idProtectionFeatures
        });
      } catch (e2) {
        LogUtils.error(e2);
      }

      try {
        this.onLoadedResolver();
      } catch (e3) {
        LogUtils.error(e3);
      }
    }
  }

  private async processCommerceStorage(idProtectionFeatures, options: {
    uxComposite: UxComposite,
  }) {
    try {
      const commerceStorage = this.commerceService.getStorage();
  
      const responseEvent: ResponseEvent = await this.commerceService.findContents({
        userId: this.authenticationService.getUserId(),
        type: "idProtection",
        raw: true
      });
  
      const commerceContent: CommerceContent = responseEvent.getSingleDoc()
      // Assign enabled features only if user have idProtection enrollment
      if (commerceContent?._id) {
        this.idProtectionFeatures = idProtectionFeatures;
      }
      await this.setupMonitoringAndAlertsInfo(commerceStorage, commerceContent, options);
    } catch (e) {
      LogUtils.error('error in getting commerceContent', e)
      this.alertService.error("Error");
    }
  }

  private async setupMonitoringAndAlertsInfo(commerceStorage, commerceContent, options: {
    uxComposite: UxComposite,
  }) {
    //const commerceStorage = this.serviceHelperService.commerceService.getStorage()
    
    const idProtectionCommerceContentId = commerceContent?._id;
    const claimedSpyDwbCommerceContentIds = commerceContent?.content?.monitored_data?.monitoredSpyDwbCommerceContentIds;

    let unreadAlerts = 0;
    const promises: Promise<void>[] = [];

    if(claimedSpyDwbCommerceContentIds) { 
      for(const spyDwbCommerceContentId of claimedSpyDwbCommerceContentIds) {
        const promise = this.commerceService.findContent(spyDwbCommerceContentId)
        .then((commerceContent: CommerceContent ) => {
          unreadAlerts = unreadAlerts + commerceContent?.content?.monitored_data?.unreadAlerts || 0
        })
        .catch((e) => {
          LogUtils.error('error in getting commerceContent', e)
          this.alertService.error("Error");
        });
        promises.push(promise);
      }
    }

    if(idProtectionCommerceContentId) { 
      const promise = this.commerceService.findContent(idProtectionCommerceContentId)
      .then((commerceContent: CommerceContent ) => {
        unreadAlerts = unreadAlerts + commerceContent?.content?.monitored_data?.unreadAlerts || 0
      })
      .catch((e) => {
        LogUtils.error('error in getting commerceContent', e)
        this.alertService.error("Error");
      });  
      promises.push(promise);
    } 

    await Promise.all(promises);
    
    this.commerceService.updateStorage({
      ...commerceStorage,
      idProtectionFeatures: this.idProtectionFeatures,
      idProtectionContentId: commerceContent?._id,  // commerceContentId for idProtection enrollment
      reportClaimedContentId: commerceContent?.data?.idProtection?.reportClaimedContentId, // commerceContentId of the report claimed for dwb id protection
      claimedSpyDwbCommerceContentId: commerceContent?.data?.idProtection?.spyDwbCommerceContentId,
      claimedSpyDwbCommerceContentIds: commerceContent?.content?.monitored_data?.monitoredSpyDwbCommerceContentIds || [],
      monitoredPeopleSearchCommerceContentIds: commerceContent?.content?.monitored_data?.monitoredPeopleSearchCommerceContentIds || [],
      idClaimBannerRejected: commerceContent?.data?.idProtection?.idClaimBannerRejected,
      unreadAlerts: unreadAlerts,
    });
  }
}