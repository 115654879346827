<div class="products">
  <mat-card *ngIf="uxComposite" class="add-edit">
    <mat-card-header>
      <mat-card-title>
        <strong>Offers and Products</strong>
        <a mat-stroked-button color="primary" [routerLink]="['/'+adminPaths.productFullMgmtV1]">Products V1</a>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="option">
        <div class="tooltip-label">
          <span>Offer level</span>
          <i class="material-icons" matTooltip="Select type you wanna add" matTooltipPosition="after">help</i>
        </div>
        <mat-button-toggle-group [(ngModel)]="addType">
          <mat-button-toggle value="stock">Stock</mat-button-toggle>
          <mat-button-toggle value="product">Product</mat-button-toggle>
          <mat-button-toggle value="offer">Offer</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="row" *ngIf="addType === 'stock'">
        <div class="col id">
          <div class="tooltip-label">
            <span>Name</span>
            <i class="material-icons" matTooltip="Input new Stock name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="newCommerceStock.draft.name" />
          </mat-form-field>
        </div>
        <div class="col id">
          <div class="tooltip-label">
            <span>Description</span>
            <i class="material-icons" matTooltip="Input new Stock description here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="description" placeholder="Description" [(ngModel)]="newCommerceStock.draft.description" />
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" [disabled]="(!newCommerceStock.draft.name)" (click)="add(newCommerceStock)">Add</button>
        </div>
      </div>

      <div class="row" *ngIf="addType === 'product'">
        <div class="col id">
          <div class="tooltip-label">
            <span>Name</span>
            <i class="material-icons" matTooltip="Input new Product name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="newCommerceProduct.draft.name" />
          </mat-form-field>
        </div>
        <div class="col id">
          <div class="tooltip-label">
            <span>Description</span>
            <i class="material-icons" matTooltip="Input new Product description here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="description" placeholder="Description" [(ngModel)]="newCommerceProduct.draft.description" />
          </mat-form-field>
        </div>
        <div class="col id">
          <div class="tooltip-label">
            <span>Stock Id</span>
            <i class="material-icons" matTooltip="Input new Stock Id here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="commerceStockId" placeholder="Stock ID" #newCommerceProductCommerceStockId />
          </mat-form-field>
        </div>
        <div class="col id">
          <div class="tooltip-label">
            <span>Quantity</span>
            <i class="material-icons" matTooltip="Input new Stock qty here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" class="quantity">
            <input matInput type="number" name="quantity" placeholder="Quantity" [(ngModel)]="newCommerceProduct.draft.commerceStockDetails[0].quantity" />
          </mat-form-field>
        </div>
        <div class="col id">
          <div class="tooltip-label">
            <span>Period</span>
            <i class="material-icons" matTooltip="Input new Stock period here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" class="period">
            <input matInput type="number" name="period" placeholder="Period" [(ngModel)]="newCommerceProduct.draft.commerceStockDetails[0].period.quantity" />
          </mat-form-field>
        </div>
        <div class="col id">
          <div class="tooltip-label">
            <span>Unit</span>
            <i class="material-icons" matTooltip="Input new Stock unit here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" class="unit">
            <mat-select name="unit" placeholder="Unit"
              [(ngModel)]="newCommerceProduct.draft.commerceStockDetails[0].period.unit">
              <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                {{ unit.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col id">
          <div class="tooltip-label">
            <span>Content Type</span>
            <i class="material-icons" matTooltip="Input new Product content type here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Content Type" [(ngModel)]="newCommerceProduct.draft.contentType" />
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" [disabled]="(!newCommerceProduct.draft.name || !newCommerceProduct.draft.contentType || !newCommerceProductCommerceStockId.value)" (click)="add(newCommerceProduct,newCommerceProductCommerceStockId.value)">Add</button>
        </div>
      </div>

      <ng-container *ngIf="addType === 'offer'">
        <div class="row mb-1">
          <div class="col">
            <div class="row">
              <div class="col">
                <div class="tooltip-label">
                  <span>Name</span>
                  <i class="material-icons" matTooltip="Input new Offer name here." matTooltipPosition="after">help</i>
                </div>
                <mat-form-field appearance="outline" floatLabel="always" class="name">
                  <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="newCommerceOffer.draft.name" />
                </mat-form-field>
              </div>
              <div class="col">
                <div class="tooltip-label">
                  <span>Ext Name</span>
                  <i class="material-icons" matTooltip="Input new Ext Title here." matTooltipPosition="after">help</i>
                </div>
                <mat-form-field appearance="outline" floatLabel="always" class="extName">
                  <input matInput type="text" name="extName" placeholder="Ext.Name" [(ngModel)]="newCommerceOffer.draft.extName" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="tooltip-label">
                  <span>Description</span>
                  <i class="material-icons" matTooltip="Input new Offer description here." matTooltipPosition="after">help</i>
                </div>
                <mat-form-field appearance="outline" floatLabel="always" class="desc">
                  <input matInput type="text" name="description" placeholder="Description" [(ngModel)]="newCommerceOffer.draft.description" />
                </mat-form-field>    
              </div>
              <div class="col">
                <div class="tooltip-label">
                  <span>Ext Description</span>
                  <i class="material-icons" matTooltip="Input new Ext Description here." matTooltipPosition="after">help</i>
                </div>
                <mat-form-field appearance="outline" floatLabel="always" class="extDesc">
                  <input matInput type="text" name="extDesc" placeholder="Ext.Desc" [(ngModel)]="newCommerceOffer.draft.extDesc" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="row">
              <div class="col">
                <div class="tooltip-label">
                  <span>Signup Offer</span>
                  <i class="material-icons" matTooltip="Input new Signup offer here." matTooltipPosition="after">help</i>
                </div>
                <mat-form-field appearance="outline" floatLabel="always" class="desc">
                  <mat-select [(ngModel)]="newCommerceOffer.draft.nonMemberOnly">
                    <mat-option [value]="true">True</mat-option>
                    <mat-option [value]="false">False</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col expanded">
                <div class="row">
                  <div class="col">
                    <div class="tooltip-label">
                      <span>New Product Id</span>
                      <i class="material-icons" matTooltip="Input new Product Id here." matTooltipPosition="after">help</i>
                    </div>
                    <mat-form-field appearance="outline" class="id">
                      <input matInput #newProductId placeholder="Product ID" (change)="addItemToArray(newCommerceOffer.draft.commerceProductIds,newProductId)">
                    </mat-form-field>
                  </div>
                </div>
                <div class="expanded-content" *ngFor="let id of newCommerceOffer.draft.commerceProductIds; let i = index " (dragover)="dragOver($event,newCommerceOffer.draft.commerceProductIds,i)" (drop)="drop($event,newCommerceOffer.draft.commerceProductIds,i)">
                  <div class="input">
                    <div class="tooltip-label" *ngIf="i === 0">
                      <span>Product Id</span>
                      <i class="material-icons" matTooltip="Input new Product Id here." matTooltipPosition="after">help</i>
                    </div>
                    <mat-form-field appearance="outline" floatLabel="always" class="id">
                      <input [disabled]="isTempRemoved(newCommerceOffer, i, true)" matInput [placeholder]="newCommerceOffer.getObjById('commerceProducts',id)?.name" [(ngModel)]="newCommerceOffer.draft.commerceProductIds[i]">
                    </mat-form-field>
                  </div>
                  <div class="action" [class.no-label]="i > 0">
                    <button *ngIf="!isTempRemoved(newCommerceOffer,i, true)" mat-icon-button color="primary" (click)="removeTempFromArray(newCommerceOffer,i, true)"><i class="material-icons">delete</i></button>
                    <button *ngIf="isTempRemoved(newCommerceOffer,i, true)" mat-icon-button color="primary" (click)="cancelRemoveTempFromArray(newCommerceOffer,i, true)"><i class="material-icons">refresh</i></button>
                    <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,newCommerceOffer.draft.commerceProductIds,i)" (dragend)="dragEnd($event,newCommerceOffer.draft.commerceProductIds,i)"><i class="material-icons">swap_vert</i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngFor="let price of newCommerceOffer.draft.commercePrice.prices; let i = index " >  <!-- (dragover)="dragOver($event,newCommerceOffer.draft.commercePrice.prices,i)" (drop)="drop($event,newCommerceOffer.draft.commercePrice.prices,i)"> -->
          <div class="col">
            <div class="tooltip-label" *ngIf="i === 0">
              <span>Sequence</span>
              <i class="material-icons" matTooltip="Input new sequence here." matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline">
              <input [disabled]="isTempRemoved(newCommerceOffer.draft.commercePrice,i, true)" matInput type="text" name="sequence" placeholder="Sequence" [(ngModel)]="price.sequence" />
            </mat-form-field>
          </div>
          <div class="col">
            <div class="tooltip-label" *ngIf="i === 0">
              <span>Amount</span>
              <i class="material-icons" matTooltip="Input new amount here." matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline">
              <input [disabled]="isTempRemoved(newCommerceOffer.draft.commercePrice,i, true)" matInput type="number" name="amount" placeholder="Amount" [(ngModel)]="price.amount"/>
            </mat-form-field>
          </div>
          <div class="col">
            <div class="tooltip-label" *ngIf="i === 0">
              <span>Quantity</span>
              <i class="material-icons" matTooltip="Input new quantity here." matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline">
              <input [disabled]="isTempRemoved(newCommerceOffer.draft.commercePrice,i, true)" matInput type="number" name="period" placeholder="Period" [(ngModel)]="price.period.quantity" />
            </mat-form-field>
          </div>
          <div class="col">
            <div class="tooltip-label" *ngIf="i === 0">
              <span>Unit</span>
              <i class="material-icons" matTooltip="Input new unit here." matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline">
              <mat-select [disabled]="isTempRemoved(newCommerceOffer.draft.commercePrice,i, true)" name="unit" placeholder="Unit" [(ngModel)]="price.period.unit">
                <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                  {{ unit.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col check" [class.no-label]="i > 0">
            <mat-checkbox [disabled]="isTempRemoved(newCommerceOffer.draft.commercePrice,i, true)" color="primary" [(ngModel)]="price.options[commercePriceOptions.bypassOnActiveTokenExists]" matTooltip="bypassOnActiveTokenExists">BATE</mat-checkbox>
          </div>
          <div class="col delete" [class.no-label]="i > 0">
            <button *ngIf="!isTempRemoved(newCommerceOffer.draft.commercePrice,i, true)" mat-icon-button color="primary" (click)="removeItemInArray(newCommerceOffer.draft.commercePrice.prices,i)"><i class="material-icons">delete</i></button>
            <!-- <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,newCommerceOffer.draft.commercePrice.prices,i)" (dragend)="dragEnd($event,newCommerceOffer.draft.commercePrice.prices,i)"><i class="material-icons">arrow_upward</i></button>
            <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,newCommerceOffer.draft.commercePrice.prices,i)" (dragend)="dragEnd($event,newCommerceOffer.draft.commercePrice.prices,i)"><i class="material-icons">arrow_downward</i></button> -->
            <button mat-icon-button color="primary" (click)="up($event,newCommerceOffer.draft.commercePrice.prices,i)"><i class="material-icons">arrow_upward</i></button>
            <button mat-icon-button color="primary" (click)="down($event,newCommerceOffer.draft.commercePrice.prices,i)"><i class="material-icons">arrow_downward</i></button>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col">
            <button class="add" mat-stroked-button color="primary" (click)="addPrice(newCommerceOffer.draft)">Add more</button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="tooltip-label">
              <span>Options</span>
              <i class="material-icons" matTooltip="Input new options here." matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline">
              <textarea matInput placeholder="Options" [ngModel]="newCommerceOffer.draft.tempClient.optionsString" (input)="processCommerceOfferOptionsEvent($event, newCommerceOffer.draft)"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button mat-raised-button color="primary" [disabled]="!isValid(newCommerceOffer)" (click)="add(newCommerceOffer)">Add</button>
          </div>
        </div>
      </ng-container>

      <div class="spacer"></div>

      <div class="row">
        <div class="col">
          <div class="tooltip-label">
            <span>Stock</span>
            <i class="material-icons" matTooltip="Input Stock id or name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="stockNameFilter" placeholder="Stock.name" (keydown)="keyDownEvent($event)" [(ngModel)]="searchOptions.filters.commerceStocks.value" />
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Product</span>
            <i class="material-icons" matTooltip="Input Product id or name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="productNameFilter" placeholder="Product.name" (keydown)="keyDownEvent($event)" [(ngModel)]="searchOptions.filters.commerceProducts.value" />
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Offer</span>
            <i class="material-icons" matTooltip="Input Offer id or name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="offerNameFilter" placeholder="Offer.name" (keydown)="keyDownEvent($event)" [(ngModel)]="searchOptions.filters.commerceOffers.value" />
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" (click)="search()">Search</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>



  <div class="searched" *ngIf="results.commerceStocks.length">
    <div class="searched-title">Stock</div>
    <mat-card>
      <mat-card-content>
        <div *ngFor="let commerceStock of results.commerceStocks; let i = index" class="searched-stocks">
          <div class="row mb-1" *ngIf="i === 0">
            <div class="col primary">Stock Id</div>
            <div class="col primary">Stock Name</div>
            <div class="col primary">Stock Description</div>
            <div class="col action"></div>
          </div>
          <div *ngIf="!commerceStock.tempClient.edit" class="row">
            <div class="col copy" (click)="copyId(commerceStock)">{{commerceStock._id}}</div>
            <div class="col copy" (click)="copyName(commerceStock)">{{commerceStock.draft.name}}</div>
            <div class="col">{{commerceStock.draft.description}}</div>
            <div class="col action">
              <button mat-raised-button color="primary" (click)="commerceStock.tempClient.edit = true">Edit</button>
            </div>
          </div>
          <div *ngIf="commerceStock.tempClient.edit" class="row">
            <div class="col">
              <mat-form-field appearance="outline" floatLabel="always" class="id">
                <input matInput class="id" placeholder="ID" [disabled]="true" [(ngModel)]="commerceStock._id">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline" floatLabel="always">
                <input matInput type="text" placeholder="Name" [(ngModel)]="commerceStock.draft.name" />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline" floatLabel="always">
                <input matInput type="text" placeholder="Desc" [(ngModel)]="commerceStock.draft.description" />
              </mat-form-field>
            </div>
            <div class="col action">
              <button mat-raised-button color="primary" class="mb-1" (click)="update(commerceStock)" [disabled]="!commerceStock.draft.name">Save</button>
              <button mat-stroked-button color="primary" (click)="commerceStock.tempClient.edit = false">Cancel</button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="searched" *ngIf="results.commerceProducts.length">
    <h2 class="searched-title">Products</h2>
    <mat-card>
      <mat-card-content>
        <div *ngFor="let commerceProduct of results.commerceProducts" class="searched-content">
          <div class="row" *ngIf="commerceProduct.tempClient.edit">
            <div class="info">
              <div class="info-section form">
                <label (click)="copyId(commerceProduct)" class="copy">Product ID</label>
                <span>
                  <mat-form-field appearance="outline" floatLabel="always">
                    <input matInput placeholder="ID" [disabled]="true" [(ngModel)]="commerceProduct._id">
                  </mat-form-field>
                </span>
              </div>
              <div class="info-section form">
                <label (click)="copyId(commerceProduct)" class="copy">Name</label>
                <span>
                  <mat-form-field appearance="outline" floatLabel="always">
                    <input matInput placeholder="Name" [(ngModel)]="commerceProduct.draft.name">
                  </mat-form-field>
                </span>
              </div>
              <div class="info-section form">
                <label (click)="copyId(commerceProduct)" class="copy">Desc</label>
                <span>
                  <mat-form-field appearance="outline" floatLabel="always">
                    <input matInput placeholder="Desc" [(ngModel)]="commerceProduct.draft.description">
                  </mat-form-field>
                </span>
              </div>
            </div>
            <div class="col">
              <div class="row mb-1">
                <div class="col primary">Quantity</div>
                <div class="col primary">Period</div>
                <div class="col primary">Unit</div>
                <div class="col primary">Content Type</div>
              </div>
              <div class="row mb-1">
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Quantity</mat-label>
                    <input matInput type="number" name="quantity" placeholder="Quantity" [(ngModel)]="commerceProduct.draft.commerceStockDetails[0].quantity" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Period</mat-label>
                    <input matInput type="number" name="period" placeholder="Period" [(ngModel)]="commerceProduct.draft.commerceStockDetails[0].period.quantity" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Unit</mat-label>
                    <mat-select name="unit" placeholder="Unit" [(ngModel)]="commerceProduct.draft.commerceStockDetails[0].period.unit">
                      <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                        {{ unit.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Content Type</mat-label>
                    <input matInput type="text" placeholder="Content Type" [(ngModel)]="commerceProduct.draft.contentType" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col primary">Stock Id ({{commerceProduct.commerceStocks[0].name}})</div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{commerceProduct.commerceStocks[0].name}}</mat-label>
                    <input matInput type="text" placeholder="Stock Id" [(ngModel)]="commerceProduct.draft.commerceStockIds[0]" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!commerceProduct.tempClient.edit">
            <div class="info">
              <div class="info-section copy" (click)="copyId(commerceProduct)"><label>Product ID</label><span>{{commerceProduct._id}}</span></div>
              <div class="info-section copy" (click)="copyName(commerceProduct)"><label>Name</label><span>{{commerceProduct.name}}</span></div>
              <div class="info-section"><label>Desc</label><span>{{commerceProduct.description}}</span></div>
            </div>
            <div class="col">
              <div class="row mb-1">
                <div class="col primary">Quantity</div>
                <div class="col primary">Period</div>
                <div class="col primary">Unit</div>
                <div class="col primary">Content Type</div>
              </div>
              <div class="row mb-1">
                <div class="col">{{ commerceProduct.draft.commerceStockDetails[0].quantity }}</div>
                <div class="col">{{ commerceProduct.draft.commerceStockDetails[0].period.quantity }}</div>
                <div class="col">{{ commerceProduct.draft.commerceStockDetails[0].period.unit }}</div>
                <div class="col">{{ commerceProduct.draft.contentType }}</div>
              </div>
              <div class="row mb-1">
                <div class="col primary copy" (click)="copyName(commerceProduct.commerceStocks[0])">Stock Id ({{commerceProduct.commerceStocks[0].name}})</div>
              </div>
              <div class="row">
                <div class="col copy" (click)="copyId(commerceProduct.commerceStocks[0])">{{commerceProduct.draft.commerceStockIds[0]}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <ng-container *ngIf="!commerceProduct.tempClient.edit">
              <button mat-raised-button color="primary" (click)="commerceProduct.tempClient.edit = true">Edit</button>
            </ng-container>
            <ng-container *ngIf="commerceProduct.tempClient.edit">
              <button mat-raised-button color="primary" class="mr-2" (click)="update(commerceProduct)" [disabled]="!commerceProduct.draft.name || !commerceProduct.draft.commerceStockIds[0]">Save</button>
              <button mat-stroked-button color="primary" (click)="commerceProduct.tempClient.edit = false">Cancel</button>
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="searched" *ngIf="results.commerceOffers.length">
    <h2 class="searched-title">Offers</h2>
    <mat-card>
      <mat-card-content>
        <div class="searched-content" *ngFor="let commerceOffer of results.commerceOffers">
          <div class="row mb-1" *ngIf="commerceOffer.tempClient.edit">
            <div class="info">
              <div class="info-section form">
                <label>Offer</label>
                <span>
                  <mat-form-field appearance="outline" floatLabel="always" class="id">
                    <mat-label>Offer</mat-label>
                    <input matInput class="id" placeholder="ID" [disabled]="true" [(ngModel)]="commerceOffer._id">
                  </mat-form-field>
                </span>
              </div>
              <div class="info-section form">
                <label>Name</label>
                <span>
                  <mat-form-field appearance="outline" floatLabel="always" class="id">
                    <mat-label>Offer Name</mat-label>
                    <input matInput type="text" placeholder="Name" [(ngModel)]="commerceOffer.draft.name" />
                  </mat-form-field>
                </span>
              </div>
              <div class="info-section form">
                <label>Desc</label>
                <span>
                  <mat-form-field appearance="outline" floatLabel="always" class="id">
                    <mat-label>Offer Description</mat-label>
                    <input matInput type="text" placeholder="Desc" [(ngModel)]="commerceOffer.draft.description" />
                  </mat-form-field>
                </span>
              </div>
            </div>
            <div class="info">
              <div class="info-section form">
                <label>Ext Name</label>
                <span>
                  <mat-form-field appearance="outline" floatLabel="always" class="id">
                    <mat-label>External Title</mat-label>
                    <input matInput type="text" placeholder="Ext.Title" [(ngModel)]="commerceOffer.draft.extName" />
                  </mat-form-field>
                </span>
              </div>
              <div class="info-section form">
                <label>Ext Desc</label>
                <span>
                  <mat-form-field appearance="outline" floatLabel="always" class="id">
                    <mat-label>External Description</mat-label>
                    <input matInput type="text" placeholder="Ext.Desc" [(ngModel)]="commerceOffer.draft.extDesc" />
                  </mat-form-field>
                </span>
              </div>
              <div class="info-section form">
                <label>Sup Offer</label>
                <span>
                  <mat-checkbox [(ngModel)]="commerceOffer.draft.nonMemberOnly" color="primary"></mat-checkbox>
                </span>
              </div>
            </div>
            <div class="col">
              <div class="row mb-1">
                <div class="col">
                  <div class="tooltip-label" style="margin-bottom:0">
                    <span class="primary">Product ID</span>
                    <i class="material-icons" matTooltip="Input Product id or name here." matTooltipPosition="after">help</i>
                  </div>
                </div>
              </div>
              <div *ngFor="let id of commerceOffer.draft.commerceProductIds; let i = index " class="expanded-content">
                <div class="input">
                  <mat-form-field appearance="outline" floatLabel="always" class="id">
                    <mat-label>{{ commerceOffer.draft.commerceProductIds[i] }} {{ commerceOffer.getObjById('commerceProducts',id)?.name }}</mat-label>
                    <input matInput [placeholder]="commerceOffer.getObjById('commerceProducts',id)?.name" [(ngModel)]="commerceOffer.draft.commerceProductIds[i]">
                  </mat-form-field>
                </div>
                <div class="action normal">
                  <button mat-icon-button color="primary" (click)="removeItemInArray(commerceOffer.draft.commerceProductIds,i)"><i class="material-icons">delete</i></button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always" class="id">
                    <mat-label>New Product</mat-label>
                    <input matInput #newProductId placeholder="Product ID" (change)="addItemToArray(commerceOffer.draft.commerceProductIds,newProductId)">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="commerceOffer.tempClient.edit">
            <div class="row mb-1">
              <div class="col">
                <ng-container *ngFor="let price of commerceOffer.draft.commercePrice.prices; let i = index ">
                  <div class="row mb-1" *ngIf="i === 0">
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Sequence</span>
                        <i class="material-icons" matTooltip="Input Sequence here." matTooltipPosition="after">help</i>
                      </div>
                    </div>                    
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Amount</span>
                        <i class="material-icons" matTooltip="Input Amount here." matTooltipPosition="after">help</i>
                      </div>
                    </div>
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Quantity</span>
                        <i class="material-icons" matTooltip="Input Quantity here." matTooltipPosition="after">help</i>
                      </div>
                    </div>
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Unit</span>
                        <i class="material-icons" matTooltip="Input Unit here." matTooltipPosition="after">help</i>
                      </div>
                    </div>
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Bate</span>
                        <i class="material-icons" matTooltip="Input Bate here." matTooltipPosition="after">help</i>
                      </div>
                    </div>
                    <div class="col action"></div>
                  </div>
                  <div class="row mb-1" (dragover)="dragOver($event,commerceOffer.draft.commercePrice.prices,i)">  <!-- (drop)="drop($event,commerceOffer.draft.commercePrice.prices,i)"> -->
                    <div class="col">
                      <mat-form-field appearance="outline" floatLabel="always" class="id">
                        <mat-label>Sequence</mat-label>
                        <input [disabled]="isTempRemoved(commerceOffer,i)" matInput type="text" name="sequence" placeholder="Sequence" [(ngModel)]="price.sequence" />
                      </mat-form-field>
                    </div>                    
                    <div class="col">
                      <mat-form-field appearance="outline" floatLabel="always" class="id">
                        <mat-label>Amount</mat-label>
                        <input [disabled]="isTempRemoved(commerceOffer,i)" matInput type="number" name="amount" placeholder="Amount" [(ngModel)]="price.amount" />
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field appearance="outline" floatLabel="always" class="id">
                        <mat-label>Quantity</mat-label>
                        <input [disabled]="isTempRemoved(commerceOffer,i)" matInput type="number" name="period" placeholder="Period" [(ngModel)]="price.period.quantity" />
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field class="id" appearance="outline" floatLabel="always">
                        <mat-label>Unit</mat-label>
                        <mat-select [disabled]="isTempRemoved(commerceOffer,i)" class="id" name="unit" placeholder="Unit" [(ngModel)]="price.period.unit">
                          <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                            {{ unit.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col check no-label">
                      <mat-checkbox [disabled]="isTempRemoved(commerceOffer,i)" color="primary" [(ngModel)]="price.options[commercePriceOptions.bypassOnActiveTokenExists]" matTooltip="bypassOnActiveTokenExists">BATE</mat-checkbox>
                    </div>
                    <div class="col action normal">
                      <button *ngIf="!isTempRemoved(commerceOffer,i)" mat-icon-button color="primary" (click)="removeTempFromArray(commerceOffer,i)"><i class="material-icons">delete</i></button>
                      <button *ngIf="isTempRemoved(commerceOffer,i)" mat-icon-button color="primary" (click)="cancelRemoveTempFromArray(commerceOffer,i)"><i class="material-icons">refresh</i></button>
                      <!-- <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,commerceOffer.draft.commercePrice.prices,i)" (dragend)="dragEnd($event,commerceOffer.draft.commercePrice.prices,i)"><i class="material-icons">swap_vert</i></button>
                      <button mat-icon-button color="primary" [draggable]="true" (dragstart)="dragStart($event,commerceOffer.draft.commercePrice.prices,i)" (dragend)="dragEnd($event,commerceOffer.draft.commercePrice.prices,i)"><i class="material-icons">swap_vert</i></button> -->
                      <button mat-icon-button color="primary" (click)="up($event,commerceOffer.draft.commercePrice.prices,i)"><i class="material-icons">arrow_upward</i></button>
                      <button mat-icon-button color="primary" (click)="down($event,commerceOffer.draft.commercePrice.prices,i)"><i class="material-icons">arrow_downward</i></button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="mb-1">
              <button mat-stroked-button color="primary" (click)="addPrice(commerceOffer.draft)">
                Add more
              </button>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>Options</mat-label>
                  <textarea matInput placeholder="options" [ngModel]="commerceOffer.draft.tempClient.optionsString" (input)="processCommerceOfferOptionsEvent($event, commerceOffer.draft)">
                  </textarea>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <div class="row mb-1" *ngIf="!commerceOffer.tempClient.edit">
            <div class="info">
              <div class="info-section copy" (click)="copyId(commerceOffer)">
                <label>Offer</label>
                <span>{{commerceOffer._id}}</span>
              </div>
              <div class="info-section copy" (click)="copyName(commerceOffer)">
                <label>Name</label>
                <span>{{commerceOffer.draft.name}}</span>
              </div>
              <div class="info-section">
                <label>Desc</label>
                <span>{{commerceOffer.draft.description}}</span>
              </div>
            </div>
            <div class="info">
              <div class="info-section">
                <label>Ext Name</label>
                <span>{{commerceOffer.draft.extName}}</span>
              </div>
              <div class="info-section">
                <label>Ext Desc</label>
                <span>{{commerceOffer.draft.extDesc}}</span>
              </div>
              <div class="info-section">
                <label>Sup Offer</label>
                <span>{{commerceOffer.nonMemberOnly ? 'True' : 'False'}}</span>
              </div>
            </div>
            <div class="col">
              <div class="tooltip-label" style="margin-bottom:12px">
                <span class="primary">Product ID</span>
                <i class="material-icons" matTooltip="Input Product id or name here." matTooltipPosition="after">help</i>
              </div>
              <div class="mb-1" *ngFor="let id of commerceOffer.draft.commerceProductIds; let i = index ">{{commerceOffer.draft.commerceProductIds[i]}}</div>
            </div>
            <div class="col">
              <div class="tooltip-label" style="margin-bottom:12px">
                <span class="primary">Product Name</span>
                <i class="material-icons" matTooltip="Input Product id or name here." matTooltipPosition="after">help</i>
              </div>
              <div class="mb-1" *ngFor="let commerceProduct of commerceOffer.commerceProducts;">{{commerceProduct.name}}</div>
            </div>
          </div>
          <ng-container *ngIf="!commerceOffer.tempClient.edit">
            <div class="row mb-1">
              <div class="col">
                <ng-container *ngFor="let price of commerceOffer.draft.commercePrice.prices; let i = index ">
                  <div class="row mb-1" *ngIf="i === 0">
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Sequence</span>
                        <i class="material-icons" matTooltip="Input Sequence here." matTooltipPosition="after">help</i>
                      </div>
                    </div>                    
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Amount</span>
                        <i class="material-icons" matTooltip="Input Amount here." matTooltipPosition="after">help</i>
                      </div>
                    </div>
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Quantity</span>
                        <i class="material-icons" matTooltip="Input Quantity here." matTooltipPosition="after">help</i>
                      </div>
                    </div>
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Unit</span>
                        <i class="material-icons" matTooltip="Input Unit here." matTooltipPosition="after">help</i>
                      </div>
                    </div>
                    <div class="col">
                      <div class="tooltip-label" style="margin-bottom:0">
                        <span>Bate</span>
                        <i class="material-icons" matTooltip="Input Bate here." matTooltipPosition="after">help</i>
                      </div>
                    </div>
                    <div class="col action"></div>
                  </div>
                  <div class="row mb-1">
                    <div class="col">{{price.sequence}}</div>
                    <div class="col">{{price.amount}}</div>
                    <div class="col">{{price.period.quantity}}</div>
                    <div class="col">{{getUnitString(price.period.unit)}}</div>
                    <div class="col">{{price.options[commercePriceOptions.bypassOnActiveTokenExists] ? 'True' : 'False'}}</div>
                    <div class="col action"></div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>Options</mat-label>
                  <textarea rows="5" matInput readonly placeholder="options" [ngModel]="commerceOffer.draft.tempClient.optionsString" (input)="processCommerceOfferOptionsEvent($event, commerceOffer.draft)">
                  </textarea>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <div class="row">
            <div class="col">
              <ng-container *ngIf="commerceOffer.tempClient.edit">
                <button mat-raised-button color="primary" (click)="update(commerceOffer)" [disabled]="!isValid(commerceOffer) && !isTempEdited(commerceOffer)" class="mr-2">Save</button>
                <button mat-stroked-button color="primary" (click)="toggleEdit(commerceOffer)">Cancel</button>
              </ng-container>
              <ng-container *ngIf="!commerceOffer.tempClient.edit">
                <button mat-raised-button color="primary" (click)="toggleEdit(commerceOffer)">Edit</button>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
